
import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import { apiGameShopSubjectDeployManagerDelete, apiGameShopSubjectDeployManagerLists, apiGameShopSubjectDeployManagerIsShow } from '@/api/shop'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'

@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData
    }
})
export default class GameUILists extends mixins() {
    /** S Data **/

    // 分页
    pager: RequestPaging = new RequestPaging()

    /** E Data **/

    /** S Methods **/

    // 更改显示开关状态
    changeStatus(value: 0 | 1, row: any) {
      apiGameShopSubjectDeployManagerIsShow({
            id: row.id,
        })
            .then(() => {
                this.getList()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 新增资讯文章
    goAdd() {
        this.$router.push({
            path: '/shop/game_shop_deploy_manger_edit',
            query: {
                mode: PageMode.ADD,
            }
        })
    }

    // 编辑资讯文章
    goEdit(data: any) {
        this.$router.push({
            path: '/shop/game_shop_deploy_manger_edit',
            query: {
                id: data.id,
                mode: PageMode.EDIT,
            }
        })
    }

    // 获取资讯文章列表
    getList() {
        return this.pager
            .request({
                callback: apiGameShopSubjectDeployManagerLists,
                params: {

                }
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 删除资讯文章
    onDel(row: any) {
      apiGameShopSubjectDeployManagerDelete({
            id: row.id
        }).then(() => {
            this.getList()
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    async created() {
        this.loadData()
    }
    /** E Life Cycle **/

    loadData() {
        return Promise.all([
          this.getList(),
        ])
    }

    async onTabClick(event: any) {
        const loading = this.$loading({
            lock: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)',
            customClass: 'switch-loading'
        })
        await this.loadData()
        loading.close()
    }
}
